import App from '@components/App';
import 'flexboxgrid/dist/flexboxgrid.css';
import './index.sass';

window.app = new App({
	target: document.querySelector('#app'),
	hydrate: true
});

if (process.env.NODE_ENV === 'production') {
	// Service Worker registration
	require('offline-plugin/runtime').install();
}
