<div class="app">
	<main class="row">
		<div class="col-xs-0 col-sm-2 col-md-2"></div>
		<div class="col-xs-12 col-sm-8 col-md-7">
			<svelte:component this={ Route } { params } />
		</div>
		<div class="col-xs-0 col-sm-2 col-md-3"></div>
	</main>
</div>

<script>
	import Navaid from 'navaid';
	import { onMount } from 'svelte';

	let Route, params={};

	function draw(m, params) {
		params = params || {};
		Route = m.default || m;
	}

	const router = (
		Navaid('/')
			.on('/', () => import('@pages/Home').then(draw))
	);

	onMount(() => {
		router.listen();

		return () => {
			router.unlisten();
		};
	});
</script>

<style lang="scss">.app {
  position: relative; }

/*# sourceMappingURL=App.svelte.css.map */</style>
